<template>
  <v-row>
    <v-col>
      <div class="cw-high-cost-warning">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="exclamation-triangle"
          class="svg-inline--fa fa-exclamation-triangle fa-w-18"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
        >
          <path
            fill="currentColor"
            d="M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2
            0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5
            28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5
            440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3
            0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z"
          />
        </svg>
        <div>
          <p class="text-caption font-weight-bold mb-0">
            <translate>
              This is a high cost credit
            </translate>
          </p>
          <p class="text-caption mb-0">
            <translate
              :translate-params="{ link: '' }"
            >
              If you cannot repay the entire debt, you will get a payment remark.
              For support, turn to the budget and debt advisor in your municipality.
              Contact information is available at %{ link }
            </translate>
            <a
              id="cw-high-cost-warning__extra-info"
              href="https://www.hallakonsument.se/"
              target="_blank"
              @click="$eventLogger.clickEvent($event)"
            >hallåkonsument.se</a>
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CwHighCostWarning',
};
</script>

<style lang="scss">
.cw-high-cost-warning {
  align-items: start;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;

  svg {
    color: #AA1E1E;
    width: 64px;
    height: 64px;
  }
}
</style>
